import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { BlockedAddressItem } from '../api/affected-address-api-client.service';
import { AffectedAddressService } from '../core/services/affected-address.service';
import { AddBlockedPostalCodeModalComponent } from './add-blocked-postal-code-modal/add-blocked-postal-code-modal.component';
import { MatPaginator } from '@angular/material/paginator';
import { Subscription } from 'rxjs';
import { SnackBarService } from '../shared/components/snackbar/snackbar.service';
import { SelectionModel } from '@angular/cdk/collections';
import { DeleteBlockedItemModalComponent } from './delete-blocked-item-modal/delete-blocked-item-modal.component';
import { AddBlockedAddressModalComponent } from './add-blocked-address-modal/add-blocked-address-modal.component';

@Component({
  selector: 'app-blocked-address',
  templateUrl: './blocked-address.component.html',
  styleUrls: ['./blocked-address.component.css']
})
export class BlockedAddressesComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('paginator') paginator: MatPaginator;

  public blockedAddressesList = new MatTableDataSource<BlockedAddressItem>();
  public blockedAddresses$ = this.affectedAddressService.addresses$;
  public selection = new SelectionModel<any>(true, []);

  public displayedColumns = [
    'Checkbox',
    'Address',
    'Zip',
    'BlockedPermanently',
    'CreatedOn'
  ];

  public totalRows: number = 0;

  private subscription: Subscription;

  public filters = new FormGroup({
    addressSearch: new FormControl(),
    zipSearch: new FormControl()
  });

  private addressFilter: string | undefined;
  private postalCodeFilter: string | undefined;

  postalCodeAdded: string | undefined;
  addressAdded: string | undefined;
  blockedPermanently: boolean;

  constructor(
    private affectedAddressService: AffectedAddressService,
    private snackBarService: SnackBarService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.subscription = this.blockedAddresses$.subscribe({
      next: response => {
        this.blockedAddressesList = new MatTableDataSource(response.sort((a, b) => a.createdOn < b.createdOn ? 1 : -1));
        this.blockedAddressesList.paginator = this.paginator;
      }, error: err => {
        this.snackBarService.showErrorSnackBar(err.message);
      }
    });
  }

  ngAfterViewInit() {
    this.blockedAddressesList.paginator = this.paginator;
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  /**
   * Apply search filters based on property (address or postal code).
   *
   */
  onSearchCriteriaChanged(filterProperty: string, event: Event) {
    if ( this.filters.get('addressSearch').value ) {
      this.addressFilter = this.filters.get('addressSearch').value.toLowerCase();
    } else {
      this.addressFilter = undefined;
    }
    this.postalCodeFilter = this.filters.get('zipSearch').value;

    if ( filterProperty === 'address' && this.addressFilter ) {
      this.blockedAddressesList.filter = this.addressFilter.trim().toLowerCase();
    } else if ( filterProperty === 'postalCode' && this.postalCodeFilter ) {
      this.blockedAddressesList.filter = this.postalCodeFilter.trim().toLowerCase();
    } else {
      this.blockedAddressesList.filter = '';
    }
  }

  /**
   * Opens add new blocked postal code modal.
   *
   */
  public async openAddPostalCodeModal(): Promise<void> {

    const modalInput = {
      postalCode: this.postalCodeAdded,
      blockedPermanently: this.blockedPermanently
    }

    this.dialog.open(AddBlockedPostalCodeModalComponent, {
      width: '620px',
      height: 'auto',
      autoFocus: false,
      disableClose: false,
      data: modalInput
    });
  }

  /**
   * Opens add new blocked address code modal.
   *
   */
  public async openAddAddressModal(): Promise<void> {
    const modalInput = {
      address: this.addressAdded,
      blockedPermanently: this.blockedPermanently
    }

    this.dialog.open(AddBlockedAddressModalComponent, {
      width: '620px',
      height: 'auto',
      autoFocus: false,
      disableClose: false,
      data: modalInput
    });
  }

  /**
   * Delete blocked postal code/address modal.
   *
   */
  public async openDeletePostalCodeModal(): Promise<void> {
    const ids = this.selection.selected.map(item => item.id);

    this.dialog.open(DeleteBlockedItemModalComponent, {
      width: '620px',
      height: 'auto',
      autoFocus: false,
      disableClose: false,
      data: {
        ids,
        unselect: () => this.selection.clear()
      }
    });
  }

  // Whether the number of selected elements matches the total number of rows.
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.blockedAddressesList.filteredData.length;
    return numSelected === numRows;
  }

  // Selects all rows if they are not all selected; otherwise clear selection.
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.blockedAddressesList.filteredData.forEach(row => this.selection.select(row));
  }
}
